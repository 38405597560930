<template>
    <div id="pageList" class="CustomerList">
        <el-tabs v-model="pageList.queryParam.activeName" @tab-click="tabHandleClick">
            <el-tab-pane label="基本信息" name="baseInfo"/>
            <el-tab-pane label="维修信息" name="repairInfo"/>
            <el-tab-pane label="保养信息" name="keepInfo"/>
        </el-tabs>
        <ListEngine :pageList="pageList" ref="pageListRef">
            <template v-slot:queryParams>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">客户电话：</el-col>
                    <el-col :span="9">
                        <el-input placeholder="请输入客户电话进行模糊匹配查询..." v-model="pageList.queryParam.phone" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">车架号：</el-col>
                    <el-col :span="9">
                        <el-input placeholder="请输入车架号进行模糊匹配查询..." v-model="pageList.queryParam.cjh" clearable/>
                    </el-col>
                </el-row>
                <el-row class="myRow">
                    <el-col :span="3" class="myColTitle">发动机号：</el-col>
                    <el-col :span="9">
                        <el-input placeholder="请输入发动机号进行模糊匹配查询..." v-model="pageList.queryParam.fdjh" clearable/>
                    </el-col>
                    <el-col :span="3" class="myColTitle">车牌号：</el-col>
                    <el-col :span="9">
                        <el-input placeholder="请输入车牌号进行模糊匹配查询..." v-model="pageList.queryParam.cph" clearable/>
                    </el-col>
                </el-row>
            </template>
            <template v-slot:tbCols>
                <el-table-column prop="F_NAME" label="客户姓名"/>
                <el-table-column prop="F_PHONE" label="客户电话"/>
                <el-table-column prop="F_CJH" label="车架号"/>
                <el-table-column prop="F_FDJH" label="发动机号"/>
                <el-table-column prop="F_CPH" label="车牌号"/>
                <el-table-column prop="F_CX" label="车型"/>
                <el-table-column prop="F_GCRQ" label="购车日期" v-if="pageList.queryParam.activeName=='baseInfo'"/>

                <el-table-column prop="F_REPAIR_TIME" label="维修时间" v-if="pageList.queryParam.activeName=='repairInfo'"/>
                <el-table-column prop="F_REPAIR_NUM" label="维修次数" v-if="pageList.queryParam.activeName=='repairInfo'"/>
                <el-table-column prop="F_FAULT" label="故障现象" v-if="pageList.queryParam.activeName=='repairInfo'"/>
                <el-table-column prop="F_REPAIR_ITEMS" label="维修项目" v-if="pageList.queryParam.activeName=='repairInfo'"/>
                <el-table-column prop="F_REPAIR_MILEAGES" label="维修里程" v-if="pageList.queryParam.activeName=='repairInfo'"/>
                <el-table-column prop="F_REPAIR_PLACE" label="维修服务中心" v-if="pageList.queryParam.activeName=='repairInfo'"/>

                <el-table-column prop="F_KEEP_TIME" label="保养时间" v-if="pageList.queryParam.activeName=='keepInfo'"/>
                <el-table-column prop="F_KEEP_NUM" label="保养次数" v-if="pageList.queryParam.activeName=='keepInfo'"/>
                <el-table-column prop="F_KEEP_ITEMS" label="保养项目" v-if="pageList.queryParam.activeName=='keepInfo'"/>
                <el-table-column prop="F_KEEP_MILEAGES" label="保养里程" v-if="pageList.queryParam.activeName=='keepInfo'"/>
                <el-table-column prop="F_KEEP_PLACE" label="保养服务中心" v-if="pageList.queryParam.activeName=='keepInfo'"/>
            </template>
        </ListEngine>

        <el-dialog title="导入数据" v-model="importDataDialogVisible" width="50%"
                   :destroy-on-close="true" :close-on-click-modal="false" :append-to-body="true">
            <div>
                <div>
                    <el-steps :active="3" finish-status="info">
                        <el-step title="步骤 1" icon="iconfont icon-xiazai" description="模板下载"></el-step>
                        <el-step title="步骤 2" icon="el-icon-edit" description="对模板进行数据填充"></el-step>
                        <el-step title="步骤 3" icon="el-icon-upload" description="上传填好数据的模板"></el-step>
                    </el-steps>
                </div>
                <div style="width:100%;margin-top: 30px;display: flex;align-items: center;justify-content: space-evenly">
                    <div>
                        <el-button type="success" round @click="downLoad">模板下载</el-button>
                    </div>
                    <div><Upload v-bind="uploadParams"></Upload></div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {ref, reactive, toRefs, computed, defineComponent, onMounted, getCurrentInstance, watch, provide} from 'vue';
    export default defineComponent ({
        name: "CustomerList",
        setup(){
            const {proxy}=getCurrentInstance();
            provide('uploadOwner', proxy);
            const utils=proxy.utils;
            let dataObj=reactive({
                pageListRef:null,
                refMap:new Map(),
                importDataDialogVisible:false,
                importType:0,
                uploadParams:{
                    limitNum:5000,
                    action:'/customer/uploadFiles',
                },
                pageList: {
                    isShowFixCol:false,
                    queryParam: {
                        activeName:'baseInfo'
                    },
                    reduceOthers:45, 
                    modelMethod: "/customer/pageData"
               }
            })
            onMounted(()=>{
                dataObj.pageListRef.getTbInst().setPagerHeight(140);
            })
            //---------------------------computed------------
            //下拉选择
            const comboSelect=computed(()=>{
                return (params) => {
                    // if(params && 'userId'==params.comboId){
                    //     return [{value:'0',label:'否'},{value:'1',label:'是'}]
                    // }
                }
            })
            const tabHandleClick=async (tab)=>{
                const loading = proxy.$loading({lock: true,text: '正在执行，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
                let tabName=tab.props.name;
                await dataObj.pageListRef.loadPageData();
                loading.close();
            }
            //下载文件
            const downLoad=async ()=>{
                let sourceName="400客户基本信息-导入模板.xlsx";
                if(dataObj.pageList.queryParam.activeName=='repairInfo')sourceName="400客户维修信息-导入模板.xlsx";
                if(dataObj.pageList.queryParam.activeName=='keepInfo')sourceName="400客户保养信息-导入模板.xlsx";
                let res = await utils.$$downLoad({cluserServer:1,url:'/customer/downLoadResourceFile', params: {sourceName:sourceName,fileName:sourceName}});
            }
            //新增导入
            const importHandler=()=>{
                dataObj.importType=0;
                dataObj.importDataDialogVisible=true;
            }
            //覆盖导入
            const appendImportHandler=()=>{
                dataObj.importType=1;
                dataObj.importDataDialogVisible=true;
            }
            //上传的时候，增加参数传入到后台
            const buildUploadParams=(formData,params,proxy)=>{
                formData.append('type',dataObj.pageList.queryParam.activeName);
                formData.append('importType',dataObj.importType);
            }
            //上传完成之后
            const afterResult=async (res)=>{
                if(res.result){
                    const loading = proxy.$loading({lock: true,text: '正在执行，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
                    await dataObj.pageListRef.loadPageData();
                    loading.close();
                }
            }
            const exportExcelHandler=()=>{
                let tbData=dataObj.pageListRef.getTbData();
                if(tbData.length>0){
                    utils.$$tools.configBox({
                        msgContent:'确定执行该操作吗?',
                        fn:async ()=> {
                            const loading = proxy.$loading({lock: true,text: '正在执行，请稍后......',spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
                            await exportExcel(tbData);
                            loading.close();
                        }
                    });
                }else{
                    proxy.$message("没有内容可以导出");
                }
            }
            const formatJson=(fields, jsonData)=>{
                return jsonData.map(rowData => fields.map(field => rowData[field]))
            }
            const exportExcel=(tbData)=>{
                return new Promise((resolve, reject) => {
                    let fields=dataObj.pageListRef.getTbInst().tbCols.fields;
                    let labels=dataObj.pageListRef.getTbInst().tbCols.labels;
                    const data = formatJson(fields, tbData);//处理之后的tbData
                    let title="";
                    switch (dataObj.pageList.queryParam.activeName){
                        case "baseInfo":title="基本信息";break;
                        case "repairInfo":title="维修信息";break;
                        case "keepInfo":title="保养信息";break;
                    }
                    proxy.excelUtils(labels, data, title);
                    resolve();
                }).catch(err => {
                    console.log('导出excel出错了')
                });
            }
            const gridLoaded=(res)=>{
                let baseField=['F_NAME','F_PHONE','F_CJH','F_FDJH','F_CPH','F_CX'];
                let baseLabel=['客户姓名','客户电话','车架号','发动机号','车牌号','车型'];
                switch (dataObj.pageList.queryParam.activeName){
                    case "baseInfo":
                        dataObj.pageListRef.getTbInst().tbCols.fields=baseField.concat(['F_GCRQ']);
                        dataObj.pageListRef.getTbInst().tbCols.labels=baseLabel.concat(['购车日期']);
                        break;
                    case "repairInfo":
                        dataObj.pageListRef.getTbInst().tbCols.fields=baseField.concat(['F_REPAIR_TIME','F_REPAIR_NUM','F_FAULT','F_REPAIR_ITEMS','F_REPAIR_MILEAGES','F_REPAIR_PLACE']);
                        dataObj.pageListRef.getTbInst().tbCols.labels=baseLabel.concat(['维修时间','维修次数','故障现象','维修项目','维修里程','维修服务中心']);
                        break;
                    case "keepInfo":
                        dataObj.pageListRef.getTbInst().tbCols.fields=baseField.concat(['F_KEEP_TIME','F_KEEP_NUM','F_KEEP_ITEMS','F_KEEP_MILEAGES','F_KEEP_PLACE']);
                        dataObj.pageListRef.getTbInst().tbCols.labels=baseLabel.concat(['保养时间','保养次数','保养项目','保养里程','保养服务中心']);
                        break;
                }
            }
            return{
                ...toRefs(dataObj),comboSelect,tabHandleClick,downLoad,importHandler,appendImportHandler,buildUploadParams,afterResult,exportExcelHandler,gridLoaded
            }
        }
    });
</script>

<style scoped>
    .CustomerList{
        width: 100%;
    }
</style>